import React from "react";
import { Provider } from "react-redux";
import { setLocale } from "react-redux-i18n";
import global_store from "../global_store";
import DisplayComments from "./display_comments";
import AddButton from "./add_button";
import RemoveButton from "./remove_button";

class DisplaySubSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: this.props.sections,
      topics: this.props.topics ? this.props.topics : [],
    };

    this.addSection = this.addSection.bind(this);
    this.removeSection = this.removeSection.bind(this);

    global_store.dispatch(setLocale(this.props.language));
  }

  addSection() {
    this.setState({
      sections: [...this.state.sections, this.additionalElement()],
    });
  }

  removeSection(index) {
    this.setState({
      sections: [
        ...this.state.sections.filter(({ created_at }) => created_at !== index),
      ],
    });
  }

  renderRemoveButton(id, target) {
    if (this.state.sections.length === 1 || id) return;

    return (
      <RemoveButton
        action={() => {
          this.removeSection(target);
        }}
      />
    );
  }

  renderComments(comments) {
    if (comments.length === 0) return;

    return <DisplayComments comments={comments} latestComment={false} />;
  }

  renderSectionFields(id, name, kind, created_at) {
    return (
      <>
        <div className="sub-section">
          {name} {this.renderRemoveButton(id, created_at)}
        </div>
        <input
          type="hidden"
          name="ecec_plan_sections[][sub_sections][][id]"
          value={id}
        ></input>
        <input
          type="hidden"
          name="ecec_plan_sections[][sub_sections][][name]"
          value={name}
        ></input>
        <input
          type="hidden"
          name="ecec_plan_sections[][sub_sections][][kind]"
          value={kind}
        ></input>
      </>
    );
  }

  renderHiddenFields(id, name, kind) {
    return (
      <>
        <input
          type="hidden"
          name="ecec_plan_sections[][sub_sections][][fields][][id]"
          value={id}
        ></input>
        <input
          type="hidden"
          name="ecec_plan_sections[][sub_sections][][fields][][name]"
          value={name}
        ></input>
        <input
          type="hidden"
          name="ecec_plan_sections[][sub_sections][][fields][][kind]"
          value={kind}
        ></input>
      </>
    );
  }

  renderFields(fields, sectionIdx) {
    return fields.map(({ id, name, description, kind, comments }, index) => {
      return (
        <div className="field" key={index}>
          <label>{name}</label>
          {this.renderHiddenFields(id, name, kind)}
          {this.renderDescription(description, kind, sectionIdx)}
          {this.renderComments(comments)}
        </div>
      );
    });
  }

  renderSection() {
    return this.state.sections.map(
      ({ id, name, kind, created_at, fields }, sectionIdx) => {
        return (
          <div className="grouped fields" key={created_at}>
            {this.renderSectionFields(id, name, kind, created_at)}
            {this.renderFields(fields, sectionIdx)}
          </div>
        );
      }
    );
  }

  render() {
    return (
      <Provider store={global_store}>
        <>
          {this.renderSection()} {<AddButton action={this.addSection} />}
        </>
      </Provider>
    );
  }
}

export default DisplaySubSection;
