import React from "react";
import { Translate } from "react-redux-i18n";
import { Card, Button } from "semantic-ui-react";

import MediaDisplayer from "./media_displayer";

class MediaCard extends React.Component {
  renderCard() {
    const attachment = this.props.attachment;
    const messageUrl = this.props.messageUrl;
    const openModal = () => { this.props.openModal(attachment.file) }

    return (
      <Card centered key={attachment.id}>
        <div className="media-wrapper" onClick={openModal}>
          <MediaDisplayer preview file={attachment.file} />
        </div>
        <Card.Content extra textAlign="left">
          {attachment.date}
          <Button as="a" fluid className="mt-10" href={messageUrl}>
            <Translate value="broadcast.media.show_message" />
          </Button>
        </Card.Content>
      </Card>
    );
  }

  render() {
    return this.renderCard();
  }
}

export default MediaCard;
