import React from "react";
import { Provider } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Icon } from "semantic-ui-react";
import global_store from "../global_store";

class DisplayFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: this.props.fields ? this.props.fields : [],
    };

    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
  }

  removeField(id) {
    this.setState({
      fields: this.state.fields.filter(({ created_at }) => created_at !== id),
    });
  }

  addField() {
    this.setState({
      fields: [
        ...this.state.fields,
        { id: "", value: "", kind: "", created_at: Date.now() },
      ],
    });
  }

  renderSortableButton() {
    if (!this.props.sortable) return;

    return (
      <a className="ui icon button right aligned">
        <Icon name="arrows alternate" />
      </a>
    );
  }

  listFields() {
    return this.state.fields.map(({ id, name, kind, created_at }) => {
      return (
        <div key={created_at} className="ui right action left input field">
          <input
            type="hidden"
            name="ecec_plan_template_sections[][fields][][id]"
            defaultValue={id}
          ></input>
          <input
            required="required"
            type="text"
            name="ecec_plan_template_sections[][fields][][name]"
            defaultValue={name}
            aria-required="true"
          ></input>
          <input
            type="hidden"
            name="ecec_plan_template_sections[][fields][][kind]"
            defaultValue={kind ? kind : "text"}
          ></input>
          {this.renderSortableButton()}
          <a
            className="ui red icon button right aligned"
            onClick={() => this.removeField(created_at)}
          >
            <Icon name="x" />
          </a>
        </div>
      );
    });
  }

  renderFields() {
    return (
      <>
        <div className="field information-fields">{this.listFields()}</div>
        <div className="field">
          <a
            className="ui primary icon button right aligned labeled"
            onClick={this.addField}
          >
            <Icon name="plus" />
            {I18n.t("pedagogical_documents.add_button")}
          </a>
        </div>
      </>
    );
  }

  render() {
    return <Provider store={global_store}>{this.renderFields()}</Provider>;
  }
}

export default DisplayFields;
