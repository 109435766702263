import React from "react";
import ReactDOMServer from "react-dom/server";
import { Translate, I18n } from "react-redux-i18n";
import backend from "../../../api/backend";
import { Provider } from "react-redux";
import global_store from "../../global_store";

import DataTable from "../../shared/datatable/base";
import Filter from "../../shared/datatable/filter";

import Segment from "./segment";
import BaseRow from "./base_row";

const TABLE_COLUMNS = ["calculated_name", "subject", "body", "created_at"];

class Index extends DataTable {
  constructor(props) {
    super(props);

    this.state = {
      table: null,
      meta: {},
      messages: [],
    };
  }

  conversationUrl = (nurseryId, messageThreadId, conversationId) => {
    return `${this.threadUrl(
      nurseryId,
      messageThreadId
    )}/conversations/${conversationId}`;
  };

  threadUrl = (nurseryId, messageThreadId) => {
    return `/${I18n._getLocale()}/nursery/${nurseryId}/broadcast/${messageThreadId}`;
  };

  messagesApiCall = (additionalParams) => (data, callback, _settings) => {
    const baseParams = new Filter().buildParams(data, TABLE_COLUMNS);
    const queryParams = { ...baseParams, ...additionalParams };

    return backend
      .getMessages(queryParams)
      .then((response) => {
        const recordsTotal = response.data.meta.total_entries;
        const messages = this.prepareData(response.data);

        this.state.messages = response.data.message_threads;
        this.state.meta = response.data.meta;

        return callback({
          recordsTotal: recordsTotal,
          recordsFiltered: recordsTotal,
          data: messages,
        });
      })
      .then(() => {
        this.onClickAccordion();
      });
  };

  prepareData(response) {
    return response.message_threads.map((message) => {
      return {
        id: message.id,
        childGroupId: message.child_group_recipient_ids[0],
        groupName: this.shortenString(removeTags(message.group_name)),
        subject: this.shortenString(removeTags(message.subject)),
        messageBody: this.shortenString(removeTags(message.body)),
        date: moment(message.created_at).format("DD-MM-YYYY"),
        actions: {},
        fullData: message,
      };
    });
  }

  shortenString(string) {
    if (string.length < 60) return string;

    return `${string.substring(0, 60)}...`;
  }

  basicSegments(data) {
    return (
      <Segment
        data={data.fullData}
        id={data.id}
        childGroupId={data.childGroupId}
        date={data.date}
        groupName={data.groupName}
        messageBody={data.messageBody}
        subject={data.subject}
        unreadConversationsCount={data.fullData.unread_conversations_count}
        hasAttachments={data.fullData.has_attachments}
        conversationsCount={data.fullData.conversations_count}
        threadUrl={this.threadUrl(this.props.nurseryId, data.id)}
      />
    );
  }

  dataTableOptions() {
    const additionalParams = {
      nursery_id: this.props.nurseryId,
      child_id: this.props.childId,
      child_group_id: this.props.childGroupId,
      grouped_by_child_group: true,
    };
    const apiCall = this.messagesApiCall(additionalParams);

    return {
      language: {
        url: this.defaulfLanguage(),
      },
      serverSide: true,
      ordering: false,
      autoWidth: false,
      processing: true,
      columns: [
        { data: "id", width: "0%", className: "hidden" },
        { data: "childGroupId", width: "0%", className: "hidden" },
        { data: "groupName", width: "10%" },
        { data: "subject", width: "30%" },
        { data: "messageBody", width: "30%" },
        { data: "date", width: "15%" },
        { data: "actions", width: "15%" },
      ],
      createdRow: (row, data) => {
        const html = ReactDOMServer.renderToString(this.basicSegments(data));

        $(row).empty();
        $(row).append(
          `<td colspan='5' class='no-border pad-bottom-only'> ${html} </td>`
        );
      },
      ajax: apiCall,
    };
  }

  getNestedSegment(segment) {
    return $(segment).find(".segments")[0];
  }

  onClickAccordion() {
    $(".table-row")
      .find("tr")
      .on(
        "click",
        function (event) {
          if ($(event.srcElement).is("a.lower-button")) return;

          const tr = $(event.currentTarget);

          const messageThreadId = parseInt(tr.children("td")[0].innerHTML);
          const childGroupId = parseInt(tr.children("td")[1].innerHTML);
          const nurseryId = this.props.nurseryId;

          const message = this.state.messages.find(
            (msg) => (msg.id == messageThreadId && msg.child_group_recipient_ids[0] == childGroupId)
          );

          if (message.conversation_ids.length == 0) {
            return (window.location.href = this.threadUrl(
              nurseryId,
              messageThreadId
            ));
          }

          const segment = $(tr).parents(".segments");
          const nestedSegment = this.getNestedSegment(segment);

          if (nestedSegment) {
            nestedSegment.remove();
            segment.addClass("stacked");
          } else {
            backend
              .getConversations(messageThreadId, { page: 1, per_page: 10, child_group_ids: message.child_group_recipient_ids })
              .then((response) => {
                const conversations = response.data.conversations;
                const htmlConversations = conversations.map(
                  function (conversation) {
                    const url = this.conversationUrl(
                      nurseryId,
                      messageThreadId,
                      conversation.id
                    );

                    return (
                      <BaseRow
                        key={conversation.id}
                        conversationName={conversation.conversation_name}
                        body={conversation.body}
                        dateOfLatestReply={conversation.date_of_latest_reply}
                        attachments={conversation.attachments_count}
                        seen={conversation.is_seen}
                        conversationUrl={url}
                      />
                    );
                  }.bind(this)
                );

                const html = (
                  <div className="ui segments">
                    <div className="ui segment">{htmlConversations}</div>
                  </div>
                );

                segment.append(ReactDOMServer.renderToString(html));
                segment.removeClass("stacked");
                return $(".open-conversation").on("click", function () {
                  window.location.href = $(this).data("link");
                });
              });
          }
        }.bind(this)
      );
  }

  componentDidMount() {
    this.updateDataTable(this.refs.main, this.dataTableOptions());
    $(".loader").remove();
  }

  shouldComponentUpdate(nextProps) {
    this.filldata(
      this.refs.main,
      this.prepareData(nextProps.messages.messages.messages)
    );
    return false;
  }

  render() {
    return (
      <Provider store={global_store}>
        <div>
          <a
            className="ui primary button"
            href={`/${I18n._getLocale()}/nursery/${
              this.props.nurseryId
            }/broadcast/new`}
          >
            <i class="plus icon"></i>
            <Translate value="broadcast.new_button" />
          </a>
          <div className="loader">
            <div className="ui active inverted dimmer">
              <div className="ui text loader">
                <Translate value="broadcast.loading" />
              </div>
            </div>
          </div>
          <table ref="main" className="table">
            <thead>
              <tr>
                <th className="hidden">ID</th>
                <th className="hidden">Child Group Id</th>
                <th>
                  <Translate value="broadcast.table.sent_to" />
                </th>
                <th>
                  <Translate value="broadcast.table.title" />
                </th>
                <th>
                  <Translate value="broadcast.table.broadcast" />
                </th>
                <th>
                  <Translate value="broadcast.table.date" />
                </th>
                <th>
                  <Translate value="broadcast.table.actions" />
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </Provider>
    );
  }
}

export default Index;
