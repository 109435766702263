import React from "react";
import { Provider } from "react-redux";
import backend from "../../../api/backend";

import { Translate, I18n } from "react-redux-i18n";
import moment from "moment";
import Linkify from "react-linkify";

import global_store from "../../global_store";
import FilePreviewer from "../../shared/file_previewer";
import FileModal from "../../shared/file_modal";
import RemoveAttachmentModal from "../../shared/remove_attachment_modal";
import FileObject from "../../shared/file_object";

import FlashMessageProvider from "../../shared/flash_message_context"


class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      conversation: null,
      selectedImageId: null,
      removedImage: null,
      attachments: []
    };
  }

  onImageClicked = (id) => {
    this.setState({ selectedImageId: id });
  }

  groupBy = function (data, key) {
    return data.reduce(function (acc, item) {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  };

  componentDidMount() {
    if (this.props.conversationId) {
      backend
        .getConversation(this.props.messageThreadId, this.props.conversationId)
        .then((response) => {
          const conversation = response.data.conversation;

          this.setState({
            conversation: conversation,
          });
        });
    } else {
      backend.getStatuses(this.props.messageThreadId).then((response) => {
        const conversations = response.data.conversations;

        this.setState({
          conversations: conversations,
        });
      });
    }

    backend.getMessageThread(this.props.messageThreadId).then((response) => {
      const messageThread = response.data.message_thread;
      const attachments = response.data.message_thread.attachments.map((attach) => new FileObject(attach));

      this.setState({
        messageThread: messageThread,
        attachments: attachments,
      });
    });
  }

  renderRecipientsGrid() {
    const conversations = this.state.conversations;
    if (!conversations) return "";

    const groupedConversations = this.groupBy(conversations, "group_name");

    return Object.keys(groupedConversations).map((key, index) => {
      const recipients =
        `${key}: ` +
        groupedConversations[key]
          .map((conv) => conv.conversation_name)
          .join(", ");

      return (
        <div
          key={index}
          className="ui grid recipients-grid"
          style={{ display: "none" }}
        >
          <div className="three wide column message-info"></div>
          <div className="thirteen wide column message-info color-teal">
            <b>{recipients}</b>
          </div>
        </div>
      );
    });
  }

  toggleRecipients() {
    if ($(".recipients-grid").is(":visible")) {
      $(".recipients-grid").hide();
      $(".toggle-recipients-btn").text(
        ` (${I18n.t("broadcast.show_recipients")})`
      );
    } else {
      $(".recipients-grid").show();
      $(".toggle-recipients-btn").text(
        ` (${I18n.t("broadcast.hide_recipients")})`
      );
    }
  }

  renderRecipient() {
    if (this.state.conversation)
      return <span>{this.state.conversation.conversation_name}</span>;
    else {
      return (
        <span>
          {this.state.messageThread.group_name}
          <a className="toggle-recipients-btn" onClick={this.toggleRecipients}>
            {" "}
            ({I18n.t("broadcast.show_recipients")})
          </a>
        </span>
      );
    }
  }

  renderHeader() {
    let messageThread = this.state.messageThread;

    return (
      <div className="ui blue segment">
        <div className="ui grid">
          <div className="eight wide column">
            <div className="ui grid">
              <div className="three wide column message-info">
                <p>
                  <Translate value="broadcast.to" />
                </p>
              </div>
              <div className="eight wide column message-info">
                <b>{this.renderRecipient()}</b>
              </div>
            </div>
            {this.renderRecipientsGrid()}
            <div className="ui grid">
              <div className="three wide column message-info">
                <p>
                  <Translate value="broadcast.sender" />
                </p>
              </div>
              <div className="eight wide column message-info">
                <b>{messageThread.sender_name}</b>
              </div>
            </div>
          </div>
          <div className="right floated eight wide column">
            <span className="date pull-right" style={{ color: "gray" }}>
              {moment(messageThread.created_at).format("HH:mm DD-MM-YYYY")}
            </span>
          </div>
        </div>
      </div>
    );
  }

  onRemoveSuccess = (removedFileId) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter((file) => {
        return file.attachment.id !== removedFileId
      })
    }))
  }

  renderAttachments() {
    if (this.state.attachments.length > 0) {
      const attachmentsHtml = this.state.attachments.map((attachment, index) => {
        return (
          <div>
            <FilePreviewer
              key={index + 1}
              file={attachment}
              index={index}
              type={attachment.type}
              onImageClick={this.onImageClicked}
              openModal="threadModal"
            />
            {this.props.removingAttachmentsEnabled==true?
            <i
              className="trash icon"
              onClick={() => { this.setState({ removedImage: attachment }, function() { 
                $('#removeAttachmentModal').modal("toggle");
                $('#removeAttachmentModal').removeClass("fade");
              }); }}
            >
            </i>
            :
            null
            }
          </div>
        );
      });

      return (
        <div>
          <strong>
            <Translate value="broadcast.attachments" />:
          </strong>
          <div>
            {attachmentsHtml}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  renderCompetenceImg(competence) {
    if (!competence.image)
      return <img className="ui avatar image" src="/file.png" />;

    return <img className="ui avatar image" src={competence.image.url} />;
  }

  renderCompetences(competences) {
    let competences_view = competences.map((competence) => {
      return (
        <div key={competence.id}>
          {this.renderCompetenceImg(competence)}
          <span>{competence.name}</span>
        </div>
      );
    });

    if (competences.length > 0) {
      return (
        <div>
          <strong>
            <Translate value="broadcast.competences" />:
          </strong>
          {competences_view}
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  renderMessageTopic() {
    const messageThread = this.state.messageThread;

    if (messageThread.message_topic) {
      return (
        <div className="ui grid">
          <div className="three wide column message-info">
            <p>
              <Translate value="broadcast.topic" />
            </p>
          </div>
          <div className="eight wide column message-info">
            <b>{messageThread.message_topic}</b>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  renderMessage() {
    const messageThread = this.state.messageThread;

    return (
      <div className="ui segment" key={messageThread.id}>
        <div className="ui grid">
          <div className="eight wide column">
            <div className="ui grid">
              <div className="three wide column message-info">
                <p>
                  <Translate value="broadcast.title" />
                </p>
              </div>
              <div className="eight wide column message-info">
                <b>{messageThread.subject}</b>
              </div>
            </div>
            {this.renderMessageTopic(messageThread)}
          </div>
        </div>

        <div className="ui segment conversation">
          <Linkify>{messageThread.body}</Linkify>
        </div>
        <div className="ui grid">
          <div className="eight wide column">
            {this.renderCompetences(messageThread.competences)}
          </div>
          <div className="eight wide column">
            {this.renderAttachments(messageThread.attachments)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.messageThread) {
      return (
        <Provider store={global_store}>
          <FlashMessageProvider>
            <div>
              <div className="ui segments">
                {this.renderHeader()}
                {this.renderMessage()}
              </div>
              
                <FileModal
                  attachments={this.state.attachments}
                  imageId={this.state.selectedImageId}
                  modalName="threadModal"
                />
                <RemoveAttachmentModal
                  image={this.state.removedImage}
                  messageThreadId={this.state.messageThread.id}
                  modalName="removeAttachmentModal"
                  onRemoveSuccess={this.onRemoveSuccess}
                />
            </div>
          </FlashMessageProvider>
        </Provider>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Index;
