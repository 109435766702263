import React from "react";
import { Icon, Grid } from "semantic-ui-react";

class DisplaySection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edition: false,
      value: this.props.value,
    };

    this.switchMode = this.switchMode.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  switchMode() {
    this.setState({ edition: !this.state.edition });
  }

  changeValue(event) {
    this.setState({ value: event.target.value });
  }

  renderEditIcon() {
    if (this.props.editable)
      return (
        <Icon color="blue" name="edit outline" onClick={this.switchMode} />
      );
  }

  renderInput() {
    if (this.state.edition) {
      return (
        <div className="ui action input">
          <input
            name={this.props.name}
            defaultValue={this.state.value}
            onChange={this.changeValue}
          ></input>
          <a className="ui green icon button" onClick={this.switchMode}>
            <Icon name="check" />
          </a>
        </div>
      );
    } else {
      return (
        <>
          <div className="ui header">
            <Grid>
              <Grid.Column width={14}>{this.state.value}</Grid.Column>
              <Grid.Column width={2} textAlign="right">
                {this.renderEditIcon()}
              </Grid.Column>
            </Grid>
          </div>
          <input
            type="hidden"
            name={this.props.name}
            defaultValue={this.state.value}
          ></input>
        </>
      );
    }
  }

  render() {
    return this.renderInput();
  }
}

export default DisplaySection;
