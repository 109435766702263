import React from "react";
import { Provider } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Checkbox } from "semantic-ui-react";
import global_store from "../../../global_store";

class TypeOfSupport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFields: this.props.active,
    };

    this.showFields = this.showFields.bind(this);
  }

  showFields() {
    const checkbox = document.getElementById(
      "template-type-of-support-checkbox"
    );

    this.setState({ showFields: checkbox.checked });
  }

  renderSingleField(name, kind, id = "") {
    return (
      <>
        <input
          type="hidden"
          name="ecec_plan_template_sections[][sub_sections][][fields][][id]"
          value={id}
        ></input>
        <input
          type="hidden"
          name="ecec_plan_template_sections[][sub_sections][][fields][][name]"
          value={name}
        ></input>
        <input
          type="hidden"
          name="ecec_plan_template_sections[][sub_sections][][fields][][kind]"
          value={kind}
        ></input>
      </>
    );
  }

  renderSection() {
    if (this.state.showFields)
      return (
        <>
          <input
            type="hidden"
            name="ecec_plan_template_sections[][sub_sections][][id]"
            value={this.props.section ? this.props.section.id : ""}
          ></input>
          <input
            type="hidden"
            name="ecec_plan_template_sections[][sub_sections][][name]"
            value={I18n.t(
              "pedagogical_documents.template.form.type_of_support.description"
            )}
          ></input>
          <input
            type="hidden"
            name="ecec_plan_template_sections[][sub_sections][][kind]"
            value="type_of_support"
          ></input>
        </>
      );
  }

  renderEmptyFields() {
    return (
      <>
        {this.renderSingleField(
          I18n.t("pedagogical_documents.template.form.type_of_support.type"),
          "dropdown"
        )}
        {this.renderSingleField(
          I18n.t(
            "pedagogical_documents.template.form.type_of_support.starting_date"
          ),
          "text"
        )}
        {this.renderSingleField(
          I18n.t(
            "pedagogical_documents.template.form.type_of_support.support_description"
          ),
          "text"
        )}
      </>
    );
  }

  renderFilledFields() {
    return this.props.fields.map((field, index) => {
      return (
        <div key={index}>
          {this.renderSingleField(field.name, field.kind, field.id)}
        </div>
      );
    });
  }

  renderFields() {
    if (this.state.showFields)
      return this.props.fields
        ? this.renderFilledFields()
        : this.renderEmptyFields();
  }

  render() {
    return (
      <Provider store={global_store}>
        <>
          <Checkbox
            id="template-type-of-support-checkbox"
            label={I18n.t(
              "pedagogical_documents.template.form.type_of_support.type_check"
            )}
            onChange={this.showFields}
            checked={this.state.showFields}
          />
          {this.renderSection()}
          {this.renderFields()}
        </>
      </Provider>
    );
  }
}

export default TypeOfSupport;
