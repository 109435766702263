import React from "react";
import moment from "moment";
import Formatter from "../shared/content/formatter";
import { Provider } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Divider, Icon, Item, Reveal } from "semantic-ui-react";
import global_store from "../global_store";

class DisplayComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: this.props.comments,
      active: false,
    };

    this.toggleComments = this.toggleComments.bind(this);
  }

  toggleComments() {
    this.setState({
      active: !this.state.active,
    });
  }

  renderCommentsButton() {
    if(this.props.latestComment && this.state.comments.length < 2) return;

    const buttonText = this.state.active
      ? I18n.t("pedagogical_documents.comments.hide_comments")
      : I18n.t("pedagogical_documents.comments.show_comments");

    const buttonIcon = this.state.active ? "chevron up" : "chevron down";

    return (
      <div className="comments-section">
        <Divider clearing />
        <div className="comments-button" onClick={this.toggleComments}>
          {buttonText} <Icon name={buttonIcon} />
        </div>
      </div>
    );
  }

  renderComments(comments = this.state.comments) {
    return (
      <Item.Group divided>
        {comments.map((comment, index) => {
          return this.renderComment(comment, index);
        })}
      </Item.Group>
    );
  }

  renderComment(comment, index) {
    const displayLatestComment = !this.props.latestComment || index != 0;

    if (!this.state.active && displayLatestComment) return;

    return (
      <Item key={comment.id}>
        <Item.Content>
          <Item.Description>
            <Formatter content={comment.comment}></Formatter>
          </Item.Description>
          <Item.Extra>
            <span>
              {comment.user.first_name
                ? comment.user.first_name + " " + comment.user.last_name
                : comment.user.email}
              ,
            </span>
            <span className="date">
              {moment(comment.created_at).format("DD-MM-Y")}
            </span>
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }

  render() {
    return (
      <Provider store={global_store}>
        <>
          {this.renderComments()}
          {this.renderCommentsButton()}
        </>
      </Provider>
    );
  }
}

export default DisplayComments;
