class FileObject {
  constructor(attachment) {
    this.attachment = attachment;
  }

  getSrc() {
    if (!this.attachment) return "";
    if (this.attachment instanceof File)
      return URL.createObjectURL(this.attachment);
    if (this.attachment.file_url) return this.attachment.file_url;
  }

  getThumb() {
    if (!this.attachment) return "";
    if (this.attachment.thumb_url) return this.attachment.thumb_url;
    if (this.attachment.file_url) return this.attachment.file_url;
  }

  getType() {
    if (!this.attachment || !this.attachment.type) return;

    return this.attachment.type.split("/")[0];
  }

  getExt() {
    if (!this.attachment || !this.attachment.type) return;

    return this.attachment.type.split("/")[1];
  }

  isVideo() {
    return (
      this.getExt() == "mp4" ||
      this.getExt() == "mov" ||
      this.getExt() == "m4v" ||
      this.getType() == "video"
    );
  }

  isImage() {
    return this.getType() == "image";
  }

  isAudio() {
    return (
      this.getExt() == "mp3" ||
      this.getExt() == "aac" ||
      this.getExt() == "flac" ||
      this.getExt() == "m4a" ||
      this.getExt() == "ogg" ||
      this.getType() == "audio"
    );
  }

  isPdf() {
    return this.getExt() == "pdf" || this.getType() == "pdf";
  }

  getFile() {
    return this.attachment;
  }
}

export default FileObject;
