import React from "react";
import { Provider } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Icon, Label } from "semantic-ui-react";
import backend from "../../api/backend";
import global_store from "../global_store";
import AddButton from "./add_button";

class Attachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: this.props.files,
      fields: [this.fileInput()],
    };

    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  addField() {
    this.setState({
      fields: [...this.state.fields, this.fileInput()],
    });
  }

  removeField(index) {
    this.setState({
      fields: this.state.fields.filter((_, i) => i !== index),
    });
  }

  removeFileConfirmation() {
    return confirm(`${I18n.t("broadcast.remove_attachment.title")}`);
  }

  removeFile(attachment_id) {
    if (!this.removeFileConfirmation()) return;

    backend
      .removePedagogicalDocumentAttachment(this.props.plan_id, attachment_id)
      .then(() => {
        this.setState({
          files: this.state.files.filter(({ id }) => id !== attachment_id),
        });
      });
  }

  fileInput() {
    return {
      key: Date.now(),
      input: (
        <input
          type="file"
          className="small"
          name="files[]"
          accept={this.props.extensions}
        ></input>
      ),
    };
  }

  renderFileInputs() {
    if (!this.props.editable) return;

    return (
      <>
        {this.state.fields.map(({ key, input }, index) => {
          return (
            <div className="field" key={key}>
              <div className="ui fluid action input">
                {input}
                <a
                  className="ui red icon button right aligned"
                  onClick={() => this.removeField(index)}
                >
                  <Icon name="x" />
                </a>
              </div>
            </div>
          );
        })}
        <AddButton action={this.addField} />
      </>
    );
  }

  renderRemoveButton(id) {
    if (!this.props.editable) return;

    return (
      <a
        onClick={() => {
          this.removeFile(id);
        }}
      >
        <i className="red x icon"></i>
      </a>
    );
  }

  renderFiles() {
    if (!this.state.files) return;

    return this.state.files.map(({ id, file_identifier, file_url }) => {
      return (
        <div className="field mb-10" key={id}>
          <Label size="medium">
            <div className="label-text">{file_identifier}</div>
            <Label.Detail>
              <a href={file_url} target="_blank">
                <i className="download icon"></i>
              </a>
              {this.renderRemoveButton(id)}
            </Label.Detail>
          </Label>
        </div>
      );
    });
  }

  renderFields() {
    return (
      <>
        {this.renderFiles()}
        {this.renderFileInputs()}
      </>
    );
  }

  render() {
    return <Provider store={global_store}>{this.renderFields()}</Provider>;
  }
}

export default Attachments;
