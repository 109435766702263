import React from "react";
import { I18n } from "react-redux-i18n";

class Actions extends React.Component {
  originalMessageUrl() {
    return this.props.threadUrl;
  }

  renderLowerButton() {
    if (this.props.conversationsCount == 0) return "";

    return (
      <div className="column">
        <a className="lower-button" href={this.originalMessageUrl()}>
          {I18n.t("broadcast.show_original")}
        </a>
      </div>
    );
  }

  renderViewButton() {
    return (
      <div className="buttons column text-center">
        <div className="column">
          <a className="ui tiny button">{I18n.t("broadcast.view_button")}</a>
        </div>
        {this.renderLowerButton()}
      </div>
    );
  }

  renderAttachmentIcon() {
    if (this.props.hasAttachments)
      return <i className="paperclip icon teal large"></i>;
    else return <i className="paperclip icon large color-white"></i>;
  }

  getColor() {
    if (this.props.conversationsCount > 0) {
      if (this.props.unreadConversationsCount > 0) return "orange";
      return "color-light-gray";
    } else {
      return "color-white";
    }
  }

  render() {
    const color = this.getColor();

    return (
      <div className="ui actions-row">
        <i className={`${color} comment icon large`}></i>
        {this.renderAttachmentIcon()}
        {this.renderViewButton()}
      </div>
    );
  }
}

export default Actions;
