import React from "react";
import { Provider } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Icon } from "semantic-ui-react";
import global_store from "../global_store";

class AddButton extends React.Component {
  renderButton() {
    return (
      <a
        className="ui primary icon button right aligned labeled"
        onClick={this.props.action}
      >
        <Icon name="plus" />
        {I18n.t("pedagogical_documents.add_button")}
      </a>
    );
  }

  render() {
    return <Provider store={global_store}>{this.renderButton()}</Provider>;
  }
}

export default AddButton;
