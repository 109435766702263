import consumer from "./consumer";

consumer.subscriptions.create("UsersChannel", {
  connected() {
  },

  disconnected() {},

  received(data) {
    if (!gon.global?.nursery_id) {
      return
    }

    if (data.new_message && data.nursery_id == gon.global?.nursery_id) {
      $("a.item.broadcast-menu").append(
        "<i class='indicator circle orange icon'></i>"
      );
    }
  },
});
