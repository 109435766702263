import React from "react";
import { I18n } from "react-redux-i18n";
$.DataTable = require("datatables");
import $ from "jquery";
import moment from "moment";

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table: null,
    };
  }

  defaulfLanguage() {
    switch (I18n._getLocale()) {
      case "fi":
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Finnish.json";
      case "sv":
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Swedish.json";
      case "ar":
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Arabic.json";
      case "vi":
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Vietnamese.json";
      case "es":
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json";
      case 'pt-BR':
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json";
      case "id":
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/Indonesian.json";
      default:
        return "//cdn.datatables.net/plug-ins/1.10.19/i18n/English.json";
    }
  }

  dataTableMoment() {
    $.fn.dataTable.moment = function (format, locale) {
      var types = $.fn.dataTable.ext.type;

      types.detect.unshift(function (d) {
        if (d === "" || d === null) {
          return "moment-" + format;
        }

        return moment(
          d.replace ? d.replace(/<.*?>/g, "") : d,
          format,
          locale,
          true
        ).isValid()
          ? "moment-" + format
          : null;
      });

      types.order["moment-" + format + "-pre"] = function (d) {
        return d === "" || d === null
          ? -Infinity
          : parseInt(
              moment(
                d.replace ? d.replace(/<.*?>/g, "") : d,
                format,
                locale,
                true
              ).format("x"),
              10
            );
      };
    };
  }

  updateDataTable(ref, options) {
    this.dataTableMoment();
    const table = $(ref).DataTable(options);

    this.state.table = table;

    $.fn.dataTable.moment("DD-MM-YYYY");
  }

  filldata(ref, data) {
    const table = $(ref).DataTable();
    table.clear();
    table.rows.add(data);
    table.draw();
    $(".loader").remove();
  }
}

export default DataTable;
