import React from "react";
import Linkify from "react-linkify";

class Formatter extends React.Component {
  linkDecorator = (decoratedHref, decoratedText, key) => (
    <a target="blank" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  )

  render() {
    return (
      <p className="format">
        <Linkify componentDecorator={this.linkDecorator}>
          {this.props.content}
        </Linkify>
      </p>
    );
  }
}

export default Formatter;

