import consumer from "./consumer";

consumer.subscriptions.create({channel: "UpdateChildStatusChannel", nursery_id: gon.global?.nursery_id}, {
  connected() {},

  disconnected() {},

  received(data) {
    if ($(`.cable__child-status-${data['id']}`).length == 0) {
      return
    }

    const language = $('.container').attr('data-locale');

    $.ajax({
      dataType: 'script',
      url: `/${language}/children/present_statuses`,
      data: {
        child_status: data['status'],
        child_id: data['id']
      }
    });
  },
});
