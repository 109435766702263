import React from "react";

import { FlashMessageConsumer } from './flash_message_context'

function withFlashMessage(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <FlashMessageConsumer>
          {(flashMessageContext) => {
            return <WrappedComponent flashMessageContext={flashMessageContext} {...this.props} />
          }}
        </FlashMessageConsumer>
      )
    }
  }
}

export default withFlashMessage;
