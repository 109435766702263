import React from "react";
import { Dropdown } from "semantic-ui-react";

class SupportDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleDropdownChange({ value }) {
    this.setState({
      value: value,
    });
  }

  renderDropdown() {
    return (
      <>
        <input
          id={this.props.inputId}
          type="hidden"
          name={this.props.name}
          defaultValue={this.state.value}
          required
        ></input>
        <Dropdown
          id={this.props.dropdownId}
          fluid
          selection
          clearable
          defaultValue={this.props.value}
          options={this.props.topics}
          onChange={(_, data) => {
            this.handleDropdownChange(data);
          }}
        />
      </>
    );
  }

  render() {
    return this.renderDropdown();
  }
}

export default SupportDropdown;
