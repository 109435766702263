import React from "react";

class FlashMessage extends React.Component {
  render() {
    return this.props.isOpen && this.props.message !== '' && (
      <div
        className={`ui ${this.props.kind || 'success'} message large transition`}
        role="alert"
      >
        <i className="close icon"></i>
        <b>{this.props.message}</b>
      </div> 
    );
  }
}

export default FlashMessage;
