import React from "react";
import { Provider } from "react-redux";
import backend from "../../../api/backend";

import { I18n } from "react-redux-i18n";
import global_store from "../../global_store";

import FileModal from "../../shared/file_modal";
import FileObject from "../../shared/file_object";
import Reply from "./reply";
import Form from "./form";

import FlashMessage from "../../shared/flash_message";

const PER_PAGE = 5;

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      replies: [],
      conversation: {},
      repliesMeta: {},
      attachments: [],
      error: '',
      selectedImageId: null,
      currentPage: 1,
    };
  }

  onImageClicked = (id) => {
    this.setState({ selectedImageId: id });
  }

  componentDidMount() {
    this.getReplies().then((data) => {
      this.setState({
        replies: data.messages.reverse(),
        attachments: data.attachments,
        conversation: data.conversation,
        repliesMeta: data.meta,
      });
    });
  }

  loadMoreReplies = () => {
    this.state.currentPage = this.state.currentPage + 1;

    this.getReplies().then((data) => {
      this.setState({
        replies: [...data.messages.reverse(), ...this.state.replies],
        attachments: [...this.state.attachments, ...data.attachments],
        repliesMeta: data.meta,
      });
    });
  }

  getReplies() {
    return backend
      .getReplies(this.props.messageThreadId, this.props.conversationId, {
        page: this.state.currentPage,
        per_page: PER_PAGE,
      })
      .then((response) => {
        const data = response.data;
        const attachments = data.messages
          .map((reply) => reply.attachments)
          .flat()
          .map((attach) => new FileObject(attach));

        return { ...data, attachments };
      });
  }

  sendMessage = (formData) => {
    backend
      .postMessage(
        this.props.messageThreadId,
        this.props.conversationId,
        formData
      )
      .then((response) => {
        const reply = response.data.reply;
        reply.user_type = "teacher";
        const files = reply.attachments.map((attach) => new FileObject(attach))

        this.setState({
          replies: [...this.state.replies, reply],
          attachments: [...this.state.attachments, ...files],
          error: ''
        });
      }).catch(error => {
          this.setState(
            {
              error: error.response.data.error
            }
          );
        }
      );
  }

  totalMessagesLoaded() {
    return (
      this.state.currentPage * PER_PAGE >= this.state.repliesMeta.total_entries
    );
  }

  renderLoadButton() {
    if (this.totalMessagesLoaded()) return <div></div>;

    return (
      <div className="ui grid">
        <div className="sixteen wide column mt-10 mb-10 text-center">
          <a
            className="ui basic centered rounded button teal"
            onClick={this.loadMoreReplies}
          >
            {I18n.t("broadcast.more")}
          </a>
        </div>
      </div>
    );
  }

  renderMessages() {
    return this.state.replies.map((message, index) => {
      return (
        <Reply
          key={index}
          body={message.body}
          attachments={message.attachments.map((attach) => new FileObject(attach))}
          name={message.name}
          date={message.created_at}
          type={message.user_type}
          hidden={message.hidden}
          onImageClicked={this.onImageClicked}
        />
      );
    });
  }

  render() {
    const conversation = this.state.conversation;

    if (
      this.state.replies &&
      conversation &&
      this.state.repliesMeta.total_entries > 0
    ) {
      return (
        <Provider store={global_store}>
          <div>
            <div className="mt-10 mb-10">
              <b>
                <p className="text-center color-teal">{`${
                  conversation.conversation_name
                } - ${I18n.t("broadcast.parent_replies")}`}</p>
              </b>
            </div>
            {this.renderLoadButton()}
            {this.renderMessages()}
            <FlashMessage
              message={this.state.error}
              isOpen={!!this.state.error}
              kind='error'
            />
            <Form
              onSendMessage={this.sendMessage}
              conversationName={conversation.conversation_name}
            />
            <FileModal
              attachments={this.state.attachments}
              imageId={this.state.selectedImageId}
              modalName="replyModal"
            />
          </div>
        </Provider>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Index;
