import React from "react";
import Modal from "./modal";

import { I18n } from "react-redux-i18n";
import { Button } from "semantic-ui-react";

import backend from "../../api/backend";

import withFlashMessage from "./flash_message_consumer"

class RemoveAttachmentModal extends React.Component {
  removeAttachment() {
    backend.removeMessageThreadAttachment(
      this.props.messageThreadId, this.props.image.attachment.id
    ).then(() => {
      this.props.flashMessageContext.show('Attachment was removed');
      if (typeof this.props.onRemoveSuccess === 'function') {
        this.props.onRemoveSuccess(this.props.image.attachment.id)
      }
    }).catch((message) => { console.log(message)});
  }

  renderFilePreview() {
    const file = this.props.image;

    if (file.isImage()) {
      return (
        <img 
          style={{ padding: "20px", maxWidth: "100%"}}
          src={file.getSrc()} 
        />
      );
    }
    else if (file.isVideo()) return <i className="big video teal icon"></i>;
    else {
      return (
        <a target="_blank" href={file.getSrc()}>
          <i className="big file teal icon"></i>
        </a>
      );
    }
  }

  renderFile() {
    return(
      <div>
        { this.renderFilePreview() }
        <p>
          {this.props.image.attachment.file_identifier}
        </p>
      </div>
    )
  }

  render() {
    if (!this.props.image) return <div></div>
    return(
      <Modal modalName={this.props.modalName}>
        <h1 className="mt-10">{I18n.t("broadcast.remove_attachment.title")}</h1>

        <p className="description">{I18n.t("broadcast.remove_attachment.description")}</p>

        { this.renderFile() }

        <div className="btn-row">
          <Button className="cancel-btn" data-dismiss="modal" onClick={() => { this.blur()}}>{I18n.t("broadcast.remove_attachment.cancel_button")}</Button>
          <Button className="remove-btn" data-dismiss="modal" onClick={() => { this.removeAttachment() }}>{I18n.t("broadcast.remove_attachment.remove_button")}</Button>
        </div>
      </Modal>
    )
  }
}

export default withFlashMessage(RemoveAttachmentModal)
