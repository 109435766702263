import consumer from "./consumer";

consumer.subscriptions.create("ImportChildrenReportChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    var import_id = data['import_id'];
    var nursery_id = data['nursery_id'];
    $.ajax({
      type: 'POST',
      url: `/nursery/${nursery_id}/children/imports/${import_id}`,
      dataType: 'script'
    });
  },
});
