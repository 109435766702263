import axios from "axios";
import URI from "urijs";

class Backend {
  constructor(locale) {
    this.locale = locale == null ? "en" : locale;
    this.api_url = `//${URI(window.location).host()}/${this.locale}/api`;
  }

  getMessages(queryParams) {
    return axios.get(this.api_url + `/v2/message_threads`, {
      params: queryParams,
    });
  }

  getMessageThreadAttachments(queryParams) {
    return axios.get(this.api_url + `/v2/message_threads/attachments`, {
      params: queryParams,
    });
  }

  getConversations(message_thread_id, queryParams) {
    return axios.get(
      this.api_url + `/v2/message_threads/${message_thread_id}/conversations`,
      {
        params: queryParams,
      }
    );
  }

  getStatuses(message_thread_id, queryParams) {
    return axios.get(
      this.api_url +
        `/v2/message_threads/${message_thread_id}/conversations/statuses`,
      {
        params: queryParams,
      }
    );
  }

  getReplies(message_thread_id, conversation_id, queryParams) {
    return axios.get(
      this.api_url +
        `/v2/message_threads/${message_thread_id}/conversations/${conversation_id}/messages`,
      {
        params: queryParams,
      }
    );
  }

  getMessageThread(id) {
    return axios.get(this.api_url + `/v2/message_threads/${id}`);
  }

  getConversation(message_thread_id, conversation_id) {
    return axios.get(
      this.api_url +
        `/v2/message_threads/${message_thread_id}/conversations/${conversation_id}`
    );
  }

  postMessage(message_thread_id, conversation_id, formData) {
    return axios.post(
      this.api_url +
        `/v2/message_threads/${message_thread_id}/conversations/${conversation_id}`,
      formData
    );
  }

  fetchBroadcastAllMessages(queryParams) {
    return axios.get(this.api_url + "/v1/me/messages/all", {
      params: queryParams,
    });
  }

  fetchChildren(nurseryId) {
    return axios.get(this.api_url + "/v1/me/nursery/child_groups", {
      params: { nursery_id: nurseryId },
    });
  }

  fetchMessageTopics() {
    return axios.get(this.api_url + "/v1/me/messages/topics");
  }

  fetchMessageTopicsStats(nurseryId, childGroupdId, startDate, endDate) {
    return axios.get(`${this.api_url}/v1/stats/message_topics`, {
      params: {
        nursery_id: nurseryId,
        child_group_id: childGroupdId,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  fetchLearningGoalsStats(nurseryId, childGroupId, startDate, endDate) {
    return axios.get(`${this.api_url}/v1/stats/learning_goals`, {
      params: {
        nursery_id: nurseryId,
        child_group_id: childGroupId,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  fetchBroadcastMessages(
    nurseryId,
    childGroupdId,
    startDate,
    endDate,
    messageTopicId,
    competenceId
  ) {
    return axios.get(`${this.api_url}/v1/stats/broadcasts`, {
      params: {
        nursery_id: nurseryId,
        child_group_id: childGroupdId,
        start_date: startDate,
        end_date: endDate,
        message_topic_id: messageTopicId,
        competence_id: competenceId,
      },
    });
  }

  fetchBroadcastStatisticsForChild(childId, startDate, endDate) {
    return axios.get(`${this.api_url}/v1/stats/broadcasts_for_child`, {
      params: {
        child_id: childId,
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  removeMessageThreadAttachment(message_thread_id, attachment_id) {
    return axios.delete(
      `${this.api_url}/v2/message_threads/${message_thread_id}/attachments/${attachment_id}`
    );
  }

  removePedagogicalDocumentAttachment(pedagogical_document_id, attachment_id) {
    return axios.delete(
      `${this.api_url}/v2/pedagogical_documents/${pedagogical_document_id}/attachments/${attachment_id}`
    );
  }
}

const container = document.getElementsByClassName("container")[0];
export default new Backend(container.getAttribute("data-locale"));
