import React from "react";
import { Grid, Placeholder } from "semantic-ui-react"

class MediaLoader extends React.Component {
  render() {
    return (
      <Grid centered columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Placeholder style={{ height: 300 }}>
              <Placeholder.Image/>
            </Placeholder>
          </Grid.Column>
          <Grid.Column>
            <Placeholder style={{ height: 300 }}>
              <Placeholder.Image/>
            </Placeholder>
          </Grid.Column>
          <Grid.Column>
            <Placeholder style={{ height: 300 }}>
              <Placeholder.Image/>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default MediaLoader;
