import consumer from "./consumer";

consumer.subscriptions.create({channel: "UpdateTeacherStatusChannel" }, {
  connected() {},

  disconnected() {},

  received(data) {
    if ($(`.cable__teacher-status-${data['id']}`).length == 0) {
      return
    }

    const language = $('.container').attr('data-locale');

    $.ajax({
      dataType: 'script',
      url: `/${language}/teachers/present_statuses`,
      data: {
        teacher_status: data['status'],
        teacher_id: data['id']
      }
    });
  }
});
