import React from "react";

import Actions from "./actions";

class Segment extends React.Component {
  segmentClass() {
    return this.props.data.conversation_ids.length > 0
      ? "stacked segments"
      : "segments";
  }

  render() {
    return (
      <div className={`messages-row table-row ui ${this.segmentClass()}`}>
        <div className="ui segment">
          <table className="ui very basic table">
            <tbody>
              <tr>
                <td className="hidden">{this.props.id}</td>
                <td className="hidden">{this.props.childGroupId}</td>
                <td style={{ width: "10%" }}>{this.props.groupName}</td>
                <td style={{ width: "30%" }}>{this.props.subject}</td>
                <td style={{ width: "30%" }}>{this.props.messageBody}</td>
                <td style={{ width: "15%" }}>{this.props.date}</td>
                <td style={{ width: "15%" }}>
                  <Actions
                    threadUrl={this.props.threadUrl}
                    unreadConversationsCount={
                      this.props.unreadConversationsCount
                    }
                    hasAttachments={this.props.hasAttachments}
                    conversationsCount={this.props.conversationsCount}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Segment;
