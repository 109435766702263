import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { Provider } from "react-redux";
import { Grid, Pagination, Card, Container } from "semantic-ui-react";

import backend from "../../../api/backend";
import global_store from "../../global_store";

import FileObject from "../../shared/file_object";
import MediaCards from "../media/media_card";
import MediaLoader from "../media/media_loader";
import MediaModal from "../media/media_modal";

class Attachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      modalOpen: false,
      selectedFile: null,
      loading: true,
      page: 1,
      total_pages: null,
      error: false,
    };

    this.per_page = 12;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handlePaginationChange = (_, { activePage }) => {
    this.messagesApiCall(activePage);
  };

  openModal(file) {
    this.setState({ 
      modalOpen: true,
      selectedFile: file
    });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  threadUrl = (nurseryId, messageId) => {
    return `/${I18n._getLocale()}/nursery/${nurseryId}/broadcast/${messageId}`;
  };

  messagesApiCall = (activePage) => {
    const queryParams = {
      per_page: this.per_page,
      page: activePage,
      nursery_id: this.props.nurseryId,
      child_id: this.props.childId,
      child_group_id: this.props.childGroupId,
      grouped_by_child_group: true,
    };

    backend
      .getMessageThreadAttachments(queryParams)
      .then((response) => {
        const attachments = this.prepareData(response.data);

        this.setState({
          attachments: attachments,
          loading: false,
          page: activePage,
          total_pages: response.data.meta.total_pages,
          error: false,
        });
      })
      .catch(() => {
        this.setState({
          attachments: [],
          loading: false,
          page: activePage,
          error: true,
        });
      });
  };

  prepareData(response) {
    return response.attachments.map((attachment) => {
      return {
        id: attachment.id,
        message_id: attachment.message_id,
        file: new FileObject(attachment),
        date: moment(attachment.created_at).format("DD-MM-YYYY"),
      };
    });
  }

  renderCards() {
    return (
      <Grid.Row className="media-scroll">
        <Grid.Column>
          <Card.Group className="media">
            {this.state.attachments.map((attachment) => {
              return (
                <MediaCards
                  key={attachment.id}
                  attachment={attachment}
                  openModal={this.openModal}
                  messageUrl={this.threadUrl(
                    this.props.nurseryId,
                    attachment.message_id
                  )}
                ></MediaCards>
              );
            })}
          </Card.Group>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderPagination() {
    if (this.state.total_pages)
      return (
        <Grid.Row>
          <Pagination
            activePage={this.state.page}
            onPageChange={this.handlePaginationChange}
            totalPages={this.state.total_pages}
          />
        </Grid.Row>
      );
  }

  renderLoader() {
    return <MediaLoader></MediaLoader>;
  }

  renderInfo() {
    return (
      <Container>
        <Translate
          value={`${
            this.state.error
              ? "broadcast.media.error"
              : "broadcast.media.no_media"
          }`}
        />
      </Container>
    );
  }

  renderMedia() {
    return (
      <Grid centered>
        {this.state.attachments.length ? this.renderCards() : this.renderInfo()}
        {this.renderPagination()}
        <MediaModal
          open={this.state.modalOpen}
          closeModal={this.closeModal}
          file={this.state.selectedFile}
        ></MediaModal>
      </Grid>
    );
  }

  componentDidMount() {
    this.messagesApiCall(this.state.page);
  }

  render() {
    return (
      <Provider store={global_store}>
        <div>
          {this.state.loading ? this.renderLoader() : this.renderMedia()}
        </div>
      </Provider>
    );
  }
}

export default Attachments;
