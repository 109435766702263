import React from "react";
import { Modal } from "semantic-ui-react";

import MediaDisplayer from "./media_displayer";

class MediaModal extends React.Component {
  renderModal() {
    const open = this.props.open;
    const closeModal = this.props.closeModal;
    const file = this.props.file;

    return (
      <Modal
        closeIcon
        basic
        open={open}
        onClose={closeModal}
        className="media-modal"
      >
        <MediaDisplayer file={file} />
      </Modal>
    );
  }

  render() {
    return this.renderModal();
  }
}

export default MediaModal;
