import React from "react";
import ReactTooltip from "react-tooltip";
import { I18n } from "react-redux-i18n";

import FileObject from "../../shared/file_object";
import FilePreviewer from "../../shared/file_previewer";

const BASE_STATE = {
  body: "",
  preview_attachments: [],
  file_attachments: [],
  sendBtnDisabled: true
};

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = BASE_STATE;

    this.sendMessage = this.sendMessage.bind(this);
    this.setBody = this.setBody.bind(this);
    this.appendFile = this.appendFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.sendBtnDisabled = this.setSendBtnDisabled.bind(this);
  }

  setBody(e) {
    this.setState({ body: e.target.value, sendBtnDisabled: !e.target.value });
  }

  sendMessage(e) {
    const formData = new FormData();

    this.state.file_attachments.forEach((attachment) => {
      formData.append("attachments[]", attachment);
    });
    formData.append("body", this.state.body);
    formData.append("skip_transcoding", false);

    this.props.onSendMessage(formData);
    this.setState(BASE_STATE);
    e.preventDefault();
  }

  setSendBtnDisabled(value) {
    this.setState({ sendBtnDisabled: value })
  }

  appendFile(e) {
    const files = [...e.target.files].map(file => new FileObject(file));
    const preview_attachments = [...this.state.preview_attachments, ...files]
    const file_attachments = [...this.state.file_attachments, ...e.target.files]
    
    this.setState({
      preview_attachments: preview_attachments,
      file_attachments: file_attachments,
      sendBtnDisabled: !file_attachments.length
    });
  }

  removeFile(fileIndex) {
    const file_attachments = this.state.file_attachments.filter(
      (_, index) => index != fileIndex
    );

    const preview_attachments = this.state.preview_attachments.filter(
      (_, index) => index != fileIndex
    );

    this.setState({
      file_attachments: file_attachments,
      preview_attachments: preview_attachments,
      sendBtnDisabled: !file_attachments.length
    });
  }

  clearForm() {
    this.setState(BASE_STATE);
  }

  imagesPreviewer() {
    if (this.state.preview_attachments.length == 0) return <div></div>;

    return this.state.preview_attachments.map((file, index) => {
      return (
        <FilePreviewer
          key={index}
          file={file}
          index={index}
          type={file.attachment.type.split("/")[0]}
          removeFile={this.removeFile}
        />
      );
    });
  }

  render() {
    return (
      <div className="ui grid">
        <div className="eight wide column">
          <div className="ui form">
            <form onSubmit={this.sendMessage}>
              <div className="field">
                <label className="color-teal">{`${I18n.t(
                  "broadcast.recipient"
                )} ${this.props.conversationName}`}</label>
                <textarea
                  onChange={this.setBody}
                  value={this.state.body}
                ></textarea>
              </div>
              <button disabled={this.state.sendBtnDisabled} type="submit" className="ui teal wide rounded button">
                <span>{I18n.t("broadcast.send")}</span>
              </button>
              <label
                className="ui icon wide button pull-right"
                htmlFor="file-button"
                data-tip={I18n.t("broadcast.attachments_types")}
              >
                <i className="plus icon"></i>
                <span>{I18n.t("broadcast.add_attachment")}</span>
              </label>
              <ReactTooltip />
              <input
                id="file-button"
                type="file"
                multiple={true}
                accept=".mp4, .mov, .mp3, .wav, .mp4, .jpg, .jpeg, .gif, .png, .pdf"
                onChange={this.appendFile}
                className="ui teal button"
              ></input>
              <a
                className="ui teal basic rounded wide button cancel-btn"
                onClick={this.clearForm}
              >
                <span>{I18n.t("broadcast.cancel")}</span>
              </a>
              <div className="ui" style={{ padding: "10px 0" }}>
                {this.imagesPreviewer()}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
