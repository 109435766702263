import React from "react";

import FlashMessage from "./flash_message"

const FlashMessageContext = React.createContext({
  isOpen: false,
  setIsOpen: () => { },
  show: () => { },
  message: '',
  setMessage: () => { },
})

export const FlashMessageConsumer = FlashMessageContext.Consumer

export function FlashMessageProvider({children}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const show = (msg, timeout = 2000) => {
    setIsOpen(true);
    setMessage(msg)
    setTimeout(() => { setIsOpen(false) }, timeout)
  }
  const value = React.useMemo(() => ({
    isOpen,
    setIsOpen,
    show,
    message,
    setMessage
  }), [isOpen, setIsOpen]);
  return (
    <FlashMessageContext.Provider value={value}>
      <FlashMessage isOpen={isOpen} message={message} />
      {children}
    </FlashMessageContext.Provider>
  );
}

export default FlashMessageProvider;
