class Filter {
  buildParams = function(data, columns) {
    let per_page = data.length;
    let page = (data.start / per_page) + 1;
    let search = data.search.value || null;
    let order_by = null;
    let sort_direction = null;

    if (data.order[0] && columns[data.order[0].column]) {
      order_by = columns[data.order[0].column];
      sort_direction = data.order[0].dir;
    }

    return { page, per_page, search, order_by, sort_direction }
  }
}

export default Filter;
