import React from "react";

class FilePreviewer extends React.Component {
  removeFile = () => {
    if (this.props.removeFile) this.props.removeFile(this.props.index);
  };

  renderRemoveButton() {
    if (this.props.removeFile)
      return (
        <span
          onClick={this.removeFile}
          className="remove-image"
          style={{ display: "inline" }}
        >
          &#215;
        </span>
      );
  }

  onClick = () => {
    if (this.props.onImageClick)
      this.props.onImageClick(this.props.file.getFile().id);
  };

  renderFile() {
    const file = this.props.file;

    if (file.isImage())
      return <img className="ui tiny rounded image" src={file.getSrc()} />;
    else if (file.isVideo()) return <i className="big video teal icon"></i>;
    else if (file.isAudio()) {
      return (
        <a target="_blank" href={file.getSrc()}>
          <i className="big file audio teal icon"></i>
        </a>
      )
    }
    else {
        return (
        <a target="_blank" href={file.getSrc()}>
          <i className="big file teal icon"></i>
        </a>
      );
    }
  }

  render() {
    if (this.props.file) {
      return (
        <div
          onClick={this.onClick}
          className="img-wrapper"
          {...(this.props.openModal &&
          (this.props.file.isImage() || this.props.file.isVideo())
            ? {
                "data-toggle": "modal",
                "data-target": `#${this.props.openModal}`,
              }
            : {})}
        >
          {this.renderFile()}
          {this.renderRemoveButton()}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default FilePreviewer;
