import React, { Component } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { Grid } from "semantic-ui-react";

const RADIAN = Math.PI / 180;

class BasePieChart extends Component {
  prepareData() {
    return this.props.data.map((element) => {
      return {
        name: element.name,
        count: element.count,
        image: element.image == null ? "" : element.image.url,
        color: element.color,
      };
    });
  }

  generate_color(color) {
    if (color) {
      return "#" + color;
    } else {
      return "#c1c1c1";
    }
  }

  customTooltip = ({ payload }) => {
    return (
      <div className="custom-tooltip">
        <p className="desc">
          {this.props.name_label}:{" "}
          {payload.length ? payload[0].payload.name : ""}
        </p>
        <p className="desc">
          {this.props.count_label}:{" "}
          {payload.length ? payload[0].payload.count : ""}
        </p>
      </div>
    );
  };

  render() {
    if (this.props.data.length == 0) {
      return <div></div>;
    }
    const data = this.prepareData();

    return (
      <PieChart width={200} height={200}>
        <Tooltip content={(props) => this.customTooltip(props)} />
        <Pie
          data={data}
          dataKey="count"
          nameKey="name"
          cx="100"
          cy="100"
          outerRadius={65}
          fill="#8884d8"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`#${entry.color}`} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}

export default BasePieChart;
