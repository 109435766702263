import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { loadTranslations, setLocale, syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';
import common_en from "../packs/app/translations/en/common.json";
import common_sv from "../packs/app/translations/sv/common.json";
import common_fi from "../packs/app/translations/fi/common.json";
import common_ar from "../packs/app/translations/ar/common.json";
import common_vi from "../packs/app/translations/vi/common.json";
import common_es from "../packs/app/translations/es/common.json";
import common_id from "../packs/app/translations/id/common.json";
import common_pt_br from "../packs/app/translations/pt-BR/common.json";
import thunk from "redux-thunk";


const translationsObject = {
  en: common_en,
  sv: common_sv,
  fi: common_fi,
  ar: common_ar,
  vi: common_vi,
  es: common_es,
  id: common_id,
  "pt-BR": common_pt_br
};

class GlobalStore {
  constructor() {
    const state = combineReducers({
      i18n: i18nReducer
    });

    this.appStore = createStore(
      state,
      compose(
        applyMiddleware(thunk)
      )
    );

    syncTranslationWithStore(this.appStore);

    this.container = document.getElementsByClassName('container')[0];

    this.appStore.dispatch(loadTranslations(translationsObject));
    this.appStore.dispatch(setLocale(this.container.getAttribute('data-locale')));
  }

  getStore = () => {
    return this.appStore;
  }
}

const store = new GlobalStore();
export default store.getStore();
