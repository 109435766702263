import React from "react";

class Modal extends React.Component {
  render() {
    return (
      <div
        className="modal fade"
        id={this.props.modalName}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="gridSystemModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div style={{ textAlign: "center" }} className="modal-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
