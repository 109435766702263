import React from "react";

import FilePreviewer from "../../shared/file_previewer";
import Linkify from "react-linkify";
import { I18n } from "react-redux-i18n";

class Reply extends React.Component {
  constructor(props) {
    super(props);

    this.onImageClick = this.onImageClick.bind(this);
  }

  onImageClick(id) {
    this.props.onImageClicked(id);
  }

  renderIcon() {
    if (this.props.type == "parent") return <i className="user icon"></i>;
    else return <i className="user outlined icon"></i>;
  }

  renderAttachments() {
    if (!this.props.attachments || this.props.attachments.length == 0)
      return <div></div>;

    const attachments = this.props.attachments.map((attachment, index) => {
      return (
        <FilePreviewer
          key={index + 1}
          file={attachment}
          index={index}
          onImageClick={this.onImageClick}
          openModal="replyModal"
        />
      );
    });

    return <div className="extra images">{attachments}</div>;
  }

  renderContent() {
    const type = this.props.type;
    const segmentClass = type === "parent" ? "border-teal" : "";
    const headerColor = type === "parent" ? "" : "color-teal";

    return (
      <div className={`segment-wrapper ${type}`}>
        <div key={2} className={`ui segment ${segmentClass}`}>
          <div className="ui grid">
            <div className={`five wide column ${headerColor}`}>
              <h4>{this.props.name}</h4>
            </div>
            <div className={`five wide column ${headerColor}`}>
              {moment(this.props.date).format("hh:mm A DD.MM.YYYY")}
            </div>
          </div>
          <div className="ui message-content">
            <div className="ui message-body">
              <Linkify>{this.props.body}</Linkify>
            </div>
            <div className="ui message-attachments">
              {" "}
              {this.renderAttachments()}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLabel() {
    return (
      <div key={1} className="label">
        {this.renderIcon()}
      </div>
    );
  }

  render() {
    if (this.props.hidden)
      return(
        <div>
          <div className="segment-wrapper teacher">
            <div className="segment">
              <div className="ui warning message large transition mb-20" role="alert">
                <i className="close icon"></i>
                <b> {`${I18n.t( "broadcast.delayed")}`} </b>
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div>
        {this.renderContent()}
        {this.renderLabel()}
      </div>
    );
  }
}

export default Reply;
