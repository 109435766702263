import React from "react";
import { I18n } from "react-redux-i18n";
import SupportDropdown from "./support_dropdown";
import DisplaySubSection from "./display_sub_section";

class DisplayTypeOfSupport extends DisplaySubSection {
  additionalElement() {
    return {
      id: "",
      created_at: Date.now(),
      name: I18n.t(
        "pedagogical_documents.template.form.type_of_support.description"
      ),
      kind: "type_of_support",
      fields: [
        {
          id: "",
          name: I18n.t(
            "pedagogical_documents.template.form.type_of_support.type"
          ),
          kind: "dropdown",
          description: "",
          comments: []
        },
        {
          id: "",
          name: I18n.t(
            "pedagogical_documents.template.form.type_of_support.starting_date"
          ),
          kind: "text",
          description: "",
          comments: []
        },
        {
          id: "",
          name: I18n.t(
            "pedagogical_documents.template.form.type_of_support.support_description"
          ),
          kind: "text",
          description: "",
          comments: []
        },
      ],
    };
  }

  renderDescription(description, kind, index) {
    if (kind == "dropdown") {
      return (
        <SupportDropdown
          inputId={`type-of-support-input-${index}`}
          dropdownId={`type-of-support-dropwdown-${index}`}
          name="ecec_plan_sections[][sub_sections][][fields][][description]"
          topics={this.state.topics}
          value={description}
        />
      );
    } else if (kind == "text") {
      return (
        <textarea
          type="text"
          name="ecec_plan_sections[][sub_sections][][fields][][description]"
          defaultValue={description}
        ></textarea>
      );
    }
  }
}

export default DisplayTypeOfSupport;
