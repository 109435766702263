import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  Text
} from "recharts";
import { Grid } from "semantic-ui-react";
import BaseBarChart from "./shared/chart/base_bar_chart"

class LabeledBarChart extends BaseBarChart {
  prepareData() {
    return this.props.data.map((element) => {
      return {
        short_name: element.short_name,
        full_name: element.full_name,
        count: element.count,
      };
    });
  }

  renderCustomizedXTick = (props) => {
    const { x, y, payload } = props;

    return (
      <Text x={x+9} y={y} angle={-18} textAnchor="end" verticalAnchor="start" fontSize="10px">
        {payload.value}
      </Text>
    );
  };

  customTooltip = ({ payload }) => {
    return (
      <div className="custom-tooltip">
        <p className="desc">
          {payload.length ? payload[0].payload.full_name : ""}
        </p>
        <p className="desc">
          {this.props.count_label}:{" "}
          {payload.length ? payload[0].payload.count : ""}
        </p>
      </div>
    );
  };

  getColor(index) {
    const colors = [
      "rgb(124,186,208)",
      "rgb(92,155,172)",
      "rgb(219,93,132)",
      "rgb(249,184,60)",
      "rgb(51,120,148)",
      "rgb(202,80,124)",
      "rgb(32,96,118)",
      "rgb(231,160,53)",
      "rgb(92,155,172)",
      "rgb(231,160,53)"
    ];

    var index = index;
    if (index >= colors.length) {
      index = index % colors.length;
    }

    return colors[index];
  }

  render() {
    if (this.props.data.length == 0) {
      return <div></div>;
    }
    const data = this.prepareData();

    return (
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column width={10}>
            <BarChart width={450} height={355} data={data}>
              <XAxis
                dataKey="short_name"
                tick={(props) => this.renderCustomizedXTick(props)}
                interval={0}
                height={70}
              />
              <YAxis
                tickCount={6}
                allowDecimals={false}
                domain={this.props.scale}
              />
              <Tooltip content={(props) => this.customTooltip(props)} />
              <Bar dataKey="count" fill="#8884d8">
                {this.props.data.map((entry, index) => (
                  <Cell key={index} fill={this.getColor(index)} />
                ))}
              </Bar>
            </BarChart>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default LabeledBarChart;
