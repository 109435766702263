import React from "react";
import { I18n } from "react-redux-i18n";
import moment from "moment";

class BaseRow extends React.Component {
  isSeen() {
    if (!this.props.seen) return <i className="orange circle thin icon"></i>;

    return "";
  }

  anyAttachments() {
    if (this.props.attachments > 0)
      return <i className="paperclip icon teal"></i>;

    return "";
  }

  isDateToday(date) {
    const parsedDate = moment(date);
    const today = moment();

    return parsedDate.diff(today, "days") == 0;
  }

  renderDate() {
    return this.isDateToday(this.props.dateOfLatestReply)
      ? I18n.t("broadcast.today")
      : moment(this.props.dateOfLatestReply).format("DD-MM-YYYY");
  }

  renderTime() {
    return moment(this.props.dateOfLatestReply).format("h:mm");
  }

  renderViewButton() {
    return (
      <a className="ui tiny button pull-right">
        {I18n.t("broadcast.view_button")}
      </a>
    );
  }

  render() {
    return (
      <div
        data-link={this.props.conversationUrl}
        className="ui grid open-conversation"
        style={{ borderTop: "1px solid #ddd" }}
      >
        <div className="three wide column" style={{ color: "gray" }}>
          {I18n.t("broadcast.reply")}
        </div>
        <div className="three wide column">{this.props.conversationName}</div>
        <div className="three wide column">{this.props.body}</div>
        <div className="one wide column"></div>
        <div className="one wide column text-right">
          {this.isSeen()}
          {this.anyAttachments()}
        </div>
        <div
          className="one wide column text-right"
          style={{ fontSize: "12px" }}
        >
          <div className="text-center">{this.renderDate()}</div>
          <div className="text-center">{this.renderTime()}</div>
        </div>
        <div
          className="one wide column text-right"
          style={{ fontSize: "12px" }}
        >
          <div className="text-center">{this.renderViewButton()}</div>
        </div>
      </div>
    );
  }
}

export default BaseRow;
