import React from "react";
import Modal from "./modal";

class FileModal extends React.Component {
  getAttachments = () => this.props.attachments.filter((attach) => attach.isVideo() || attach.isImage());

  getIndex = () => {
    return this.getAttachments().findIndex(
      (attachment) => attachment.getFile().id == this.state.imageId
    );
  };

  findFile = () => {
    return this.getAttachments().find(
      (attachment) => attachment.getFile().id == this.state.imageId
    );
  };

  getTotalAttachments = () => this.getAttachments().length;

  nextImage = () => {
    let index = this.getIndex();

    const nextIndex = (index + 1) % this.getTotalAttachments();
    this.setState({ imageId: this.getAttachments()[nextIndex].getFile().id });
  };

  previousImage = () => {
    let index = this.getIndex();
    if (index == 0) index = this.getTotalAttachments();

    const nextIndex = (index - 1) % this.getTotalAttachments();

    this.setState({ imageId: this.getAttachments()[nextIndex].getFile().id });
  };

  componentWillMount() {
    this.setState({
      imageId: this.props.imageId,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.imageId !== this.state.imageId) {
      this.setState({ imageId: nextProps.imageId });
    }
  }

  renderFooter = () => {
    if (this.getTotalAttachments() > 1) {
      return (
        <div className="modal-footer">
          <button
            onClick={this.previousImage}
            type="button"
            className="btn btn-default pull-left"
          >
            <i className="angle left icon"></i>
          </button>
          <button
            onClick={this.nextImage}
            type="button"
            className="btn btn-default pull-right"
          >
            <i className="angle right icon"></i>
          </button>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  renderFile() {
    const file = this.findFile();

    if(!file) return <div></div>

    if (file.isImage()) {
      return (
        <img
          style={{ padding: "20px", maxWidth: "100%" }}
          src={file.getSrc()}
        />
      );
    } else if (file.isVideo()) {
      return (
        <video
          style={{ width: "100%" }}
          src={file.getSrc()}
          type={`video/${file.getExt()}`}
          controls
        >
          Your browser does not support HTML video.
        </video>
      );
    }
  }

  render() {
    return (
      <Modal modalName={this.props.modalName}>
        {this.renderFile()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

export default FileModal;
