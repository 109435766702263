import React from "react";
import { Icon, Image } from "semantic-ui-react";

class MediaDisplayer extends React.Component {
  renderFile() {
    const file = this.props.file;
    const fileSrc = file.getSrc();
    const fileExt = file.getExt();

    if (file.isImage()) return this.renderImage(fileSrc);
    else if (file.isVideo()) return this.renderVideo(fileSrc, fileExt);
    else if (file.isAudio()) return this.renderAudio(fileSrc, fileExt);
    else if (file.isPdf()) return this.renderPdf(fileSrc);
    else return this.renderArchive(fileSrc);
  }

  renderImage(src) {
    return (
      <Image
        src={src}
        centered
        className={this.props.preview ? "media-img" : ""}
      />
    );
  }

  renderVideo(src, extension) {
    if (this.props.preview) {
      return (
        <Icon bordered inverted color="teal" name="file video" size="huge" />
      );
    } else {
      return (
        <video
          src={src}
          type={`video/${extension}`}
          controls
          className="media-video"
        >
          <source src={src} type={`video/${extension}`} />
          Your browser does not support HTML video.
        </video>
      );
    }
  }

  renderAudio(src, extension) {
    if (this.props.preview) {
      return (
        <Icon bordered inverted color="teal" name="file audio" size="huge" />
      );
    } else {
      return (
        <audio
          src={src}
          type={`audio/${extension}`}
          controls
          className="media-audio"
        >
          <source src={src} type={`audio/${extension}`} />
          Your browser does not support the audio tag.
        </audio>
      );
    }
  }

  renderPdf(src) {
    if (this.props.preview) {
      return (
        <Icon
          bordered
          inverted
          color="teal"
          name="file pdf outline"
          size="huge"
        />
      );
    } else {
      return <embed src={src} width="90%" height="100%"></embed>;
    }
  }

  renderArchive(src) {
    if (this.props.preview) {
      return <Icon bordered inverted color="teal" name="file" size="huge" />;
    } else {
      return (
        <a target="_blank" href={src}>
          <Icon color="teal" name="file" size="huge" />
        </a>
      );
    }
  }

  render() {
    if (this.props.file) {
      return this.renderFile();
    } else {
      return <div></div>;
    }
  }
}

export default MediaDisplayer;
